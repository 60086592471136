const developmentApiDomain = "http://triton-nb/BookingServices/api";
const productionApiDomain = "https://bookingapi.vespiario-system.com/BookingServices/api";
const stagingApiDomain = "https://bookingapidev.vespiario-system.com/BookingServices/api";

const apiDomain = productionApiDomain;

export const API = {
    // booking register
    CUSTOMER_CHECK_SERVICE: `${apiDomain}/CustomerCheckService`,
    GET_SERVICE_LIST: `${apiDomain}/GetServiceList`,
    GET_SERVICE_DATE: `${apiDomain}/GetServiceDate`,
    GET_SERVICE_DATE_DIS: `${apiDomain}/GetServiceUnavailableDate`,
    GET_SERVICE_TIME: `${apiDomain}/GetServiceTime`,
    CUSTOMER_REGISTER: `${apiDomain}/CustomerRegisterService`,

    // admin
    ADMIN_LOGIN: `${apiDomain}/AdminAuthen`,
    ADMIN_CHECK_TOKEN: `${apiDomain}/AdminCheckToken`,
    ADMIN_CHECK_INFO: `${apiDomain}/AdminCheckInfo`,
    ADMIN_BOOKING_LIST: `${apiDomain}/AdminBookingList`,
    GET_MODEL_LIST: `${apiDomain}/GetModelList`,
    ADMIN_BOOKING_CREATE: `${apiDomain}/AdminBookingAdd`,
    ADMIN_BOOKING_EDIT: `${apiDomain}/AdminBookingEdit`,
    ADMIN_STATUS_COMPLETE: `${apiDomain}/AdminBookingStatusDone`,
}